<template>
  <div>
    <v-list-item style="padding-right: 0">
      <v-row no-gutters>
        <v-col cols="9">
          <v-icon v-if="user.isAdmin == 1">mdi-star</v-icon> {{ user.username }}
        </v-col>
        <v-col cols="3">
          <v-row
            no-gutters
            :class="canBeDeleted ? 'justify-space-between' : 'justify-end'"
          >
            <v-btn color="primary" small @click.stop="dialog = true">
              Ändern
            </v-btn>

            <v-btn
              v-if="canBeDeleted"
              color="error"
              small
              @click.stop="confirmation = true"
            >
              Löschen
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-list-item>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ user.username }}
        </v-card-title>

        <v-form ref="form" v-model="valid" lazy-validation @submit="saveUser">
          <v-card-text>
            <v-text-field
              v-model="username"
              :counter="20"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>

            <v-switch
              v-model="changePassword"
              label="Passwort ändern"
            ></v-switch>

            <v-text-field
              v-if="changePassword"
              v-model="password"
              :rules="passwordRules"
              label="Passwort"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="close()">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :disabled="!valid"
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmation" max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ user.username }} löschen?
        </v-card-title>

        <v-card-text>
          Die Daten werden nicht gelöscht. Aber der Nutzer kann sich nicht mehr
          anmelden und wird aus der Datenbank gelöscht.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="close()">Abbrechen</v-btn>
          <v-btn @click="deleteUser()" color="error">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, toRefs, computed } from 'vue'
import { useComStore } from '../stores/communication'
export default {
  name: 'UserComponent',
  components: {},
  props: {
    user: Object
  },
  setup(props) {
    const { user } = toRefs(props)
    const comStore = useComStore()

    const username = ref(user.value.username)
    const password = ref('')
    const changePassword = ref(false)
    const dialog = ref(false)
    const valid = ref(false)
    const confirmation = ref(false)

    const canBeDeleted = computed(() => user.value.isAdmin === '0')

    const refForm = ref(null)

    function saveUser() {
      if (refForm.value.validate()) {
        var data = {
          id: user.value.id,
          username: username.value,
          password: changePassword.value ? password.value : null
        }
        comStore.editUser(data)
        close()
      }
    }

    function deleteUser() {
      var data = {
        id: user.value.id,
        username: username.value
      }
      comStore.deleteUser(data)
      comStore.getUsers()
      close()
    }

    function close() {
      dialog.value = false
      confirmation.value = false
    }

    return {
      username,
      canBeDeleted,
      dialog,
      confirmation,
      changePassword,
      password,
      valid,
      nameRules: [
        (v) => !!v || 'Name darf nicht leer sein',
        (v) =>
          (v && v.length <= 20) || 'Name darf nicht länger als 20 Zeichen sein'
      ],
      passwordRules: [
        (v) => !!v || 'Passwort darf nicht leer sein',
        (v) =>
          (v && v.length <= 20) ||
          'Passwort darf nicht länger als 20 Zeichen sein'
      ],
      saveUser,
      close,
      deleteUser
    }
  }
}
</script>

<style scoped></style>
