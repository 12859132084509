//@ts-check
var _ = require('lodash')
//##### KONSTANTEN
import { makeId, findLeftSpace } from './helper'
import {
  GLOBAL_DEFAULTS,
  ID,
  getColorArray,
  BAR_CONST,
  LINE_CONST
} from './constants'

/**
 *
 * @param {import('./typedef').JsonDatei} jsonData
 * @returns {{fragen: import('./typedef').Frage[], kategorien: import('./typedef').Kategorie[], groupsIdsWithBigGroupName: number[]}}
 */
export const prepareAllTextsForSvg = (jsonData) => {
  console.log(
    '🚀 ~ file: builder.js:19 ~ prepareAllTextsForSvg ~ jsonData:',
    jsonData
  )
  const groupsIdsWithBigGroupName = []
  jsonData.fragen.forEach((frage) => {
    if (frage.unterfragen && frage.unterfragen.length > 0) {
      frage.unterfragen = frage.unterfragen.map((unterfrage) => {
        let svgFrage = splitText(
          unterfrage.frage,
          BAR_CONST.maxSubquestionLabelLength
        )
        return { ...unterfrage, svgFrage, bigLabel: svgFrage.length > 2 }
      })
    }
  })
  jsonData.kategorien.forEach((kategorie) => {
    kategorie.gruppen = kategorie.gruppen.map((gruppe) => {
      let svgName = splitText(gruppe.name, BAR_CONST.maxSubquestionLabelLength)
      let bigLabel = false
      if (svgName.length > 2) {
        bigLabel = true
        groupsIdsWithBigGroupName.push(gruppe.id)
      }
      return { ...gruppe, svgName, bigLabel: svgName.length > 2 }
    })
  })

  return {
    fragen: jsonData.fragen,
    kategorien: jsonData.kategorien,
    groupsIdsWithBigGroupName
  }
}

/**
 * @function splitText
 * @param {string} text - Text
 * @param {number} maxLength - Maximale Zeichenlänge
 * @returns {string[]} Text aufgeteilt in Teilstücke
 */
function splitText(text, maxLength) {
  if (!text) return []
  const startLength = text.length
  if (startLength <= maxLength) return [text]

  var parts = []
  var breakPoint = findLeftSpace(text, maxLength)

  parts.push(text.substring(0, breakPoint))
  text = text.substring(breakPoint, text.length)

  while (text.length > maxLength) {
    breakPoint = findLeftSpace(text, maxLength) || text.length

    parts.push(text.substring(1, breakPoint))
    text = text.substring(breakPoint, text.length)
  }
  parts.push(text.trim())
  return parts
}
